import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { Waitlist, WaitlistActions, WaitlistAdd } from '../../../types/waitlist.type';
import { sfcApi } from '../../api';

export const waitlistApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllWaitlists: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `waitlists${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
    }),
    getWaitlist: builder.query<{ waitlist: Waitlist }, number>({
      query: (id) => `waitlists/${id}`,
    }),
    addWaitlist: builder.mutation<{ waitlist: WaitlistAdd }, WaitlistAdd>({
          query: (data) => ({
            url: `waitlists/add?course_id=${data.course_id}&child_id=${data.child_id}`,
            method: 'GET',
          }),
          invalidatesTags: ['Waitlist']
        }),
    actionsWaitlist: builder.mutation<{ waitlist: WaitlistActions }, WaitlistActions>({
      query: (data) => ({
        url: `waitlists/${data.id}/${data.action}`,
        method: 'GET',
      }),
      invalidatesTags: ['Waitlist']
    }),
    archiveWaitlist: builder.mutation<{ waitlist: Partial<Waitlist> }, number>({
      query: (id) => ({
        url: `waitlists/${id}/archive`,
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useGetAllWaitlistsQuery,
  useGetWaitlistQuery,
  useAddWaitlistMutation,
  useActionsWaitlistMutation,
  useArchiveWaitlistMutation,
} = waitlistApi;
