import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import { Col, Row, Spinner, Stack, Form } from "react-bootstrap";
import { useToast } from "../../../../../context/ToastContext";
import { LinkCell } from "../../../../../components/tables/cellComponents/LinkCell";
import { useGetLocationQuery } from "../../../../../services/endpoints/places/location";
import { formatResponse, SFCLocation } from "../../../../../types/place.types";
import { SimpleButton } from "../../../../../components/buttons/SimpleButton";
import { DetailSection, LabelText } from "../../../../../components/generic";
import { getCurrentUser, LatLng } from "../../../../../services/helper";
import { IconLink } from "../../../../../components/iconLink/IconLink";
import { ReactComponent as LedgerIcon } from "../../../../../assets/icons/ledger.svg";
import {
  APIProvider,
  Map,
  MapEvent,
  AdvancedMarker,
} from "@vis.gl/react-google-maps";
import { useMyLocationContext } from "../../../../../context";
import { FaBuilding } from "react-icons/fa";
import { Venue } from "../../../../../types/venue.type";
import { RiAdminFill } from "react-icons/ri";
import { User } from "../../../../../services/endpoints/people/user";

const currentUser = getCurrentUser();

export const LocationDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const isSuperUser = currentUser?.isSuper;
  const isAdminUser = currentUser?.isAdmin;
  const { data, error, isLoading } = useGetLocationQuery(+params.id!);
  const [location, setLocation] = useState<SFCLocation>();
  const { myLocation } = useMyLocationContext();
  const [center, setCenter] = useState<LatLng>();
  const mapRef = useRef<any>(null);
  const [showVenues, setShowVenues] = useState<boolean>(true);
  const [showAdmin, setShowAdmin] = useState<boolean>(true);
  const [showInstructor, setShowInstructor] = useState<boolean>(true);
  // const [position, setPosition] = useState({ lat: 40.7128, lng: -74.006 });
  const embassyIcon = "https://cdn-icons-png.flaticon.com/128/684/684908.png"; // Example embassy icon URL

  // Callback to handle map load
  const handleMapLoad = (event: MapEvent<unknown>) => {
    mapRef.current = event.map; // Store the map instance in the ref
  };
  useEffect(() => {
    if (error && !isLoading) {
      addToast("Error while loading location", "error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setLocation(formatResponse(data?.location));
      setCenter({
        lat: data?.location?.latitude,
        lng: data?.location?.longitude,
      });
    }
  }, [data]);

  if (isLoading || !location) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    );
  }

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: "#EFEFEF" }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button
            className="btn btn-link p-0 text-info"
            onClick={() => navigate("/admin/places/locations")}
          >
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: "#888888", fontSize: "1rem" }}
          >
            Locations List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">{`${location?.title}`}</h2>
        </Stack>
        <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() =>
              navigate(`/admin/places/locations/${location?.id}/edit`)
            }
          >
            Edit location
          </SimpleButton>
        </Stack>
      </div>
      <div>
        <Stack gap={4} className="p-4">
          <Row>
            <Col>
              <DetailSection title="Details">
                <LabelText label="Business Entity Name:">
                  {location?.business_entity_name}
                </LabelText>
                <LabelText label="Business Phone Number:">
                  {location?.phone_number}
                </LabelText>
                <LabelText label="Primary City:">
                  {location?.primary_city}
                </LabelText>
                <LabelText label="Primary City:">
                  <LinkCell
                    content={location.manager?.full_name ?? "Empty"}
                    url={`/admin/dash-users/admins/${
                      location.manager?.id ?? ""
                    }`}
                  />
                </LabelText>
                <LabelText label="Scheduling Email:">
                  {location?.scheduling_email}
                </LabelText>
                <LabelText label="Invoicing Email:">
                  {location?.invoicing_email}
                </LabelText>
                <LabelText label="Chef Hotline Number:">
                  {location?.hotline}
                </LabelText>
                <LabelText label="Tax ID Number:">
                  {location?.tax_id_number}
                </LabelText>
                <LabelText label="Remittance Mailing Addresses:">
                  {location?.addresses.length > 0 ? (
                    [location?.addresses[0]].map((a) => (
                      <>
                        {`${a.street}`}
                        {a.unit ? `, ${a.unit}` : ""}
                        <br />
                        {`${a.city}, ${a.state} ${a.zip_code}`}
                      </>
                    ))
                  ) : (
                    <></>
                  )}
                </LabelText>
              </DetailSection>
            </Col>
            <Col>
              {isSuperUser ||
              (isAdminUser &&
                +(currentUser?.location_id ?? 0) === location.id) ? (
                <DetailSection title="Reports">
                  <IconLink
                    icon={<LedgerIcon />}
                    text={"Summary Session Financials"}
                    url={`/admin/places/locations/${location.id}/ledger`}
                  />
                  <IconLink
                    icon={<LedgerIcon />}
                    text={"Gross Revenue Report"}
                    url={`/admin/places/locations/${location.id}/weekly-report`}
                  />
                </DetailSection>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <APIProvider apiKey={"AIzaSyBeQgArHrg3xn6y-9xwY-fjmwxqqpFZFwY"}>
            <div style={{ width: "100%", height: "500px" }}>
              <Form>
                <div className="mb-3">
                  <Form.Check
                    inline
                    label="Venues"
                    name="group1"
                    type="checkbox"
                    checked={showVenues}
                    onChange={() => setShowVenues(!showVenues)}
                  />

                  <Form.Check
                    inline
                    label="Admin"
                    name="group1"
                    type="checkbox"
                    checked={showAdmin}
                    onChange={() => setShowAdmin(!showAdmin)}
                  />

                  <Form.Check
                    inline
                    label="Instructor"
                    name="group1"
                    type="checkbox"
                    checked={showInstructor}
                    onChange={() => setShowInstructor(!showInstructor)}
                  />
                </div>
              </Form>
              <Map
                mapId="7914a7f3b7361cce"
                defaultCenter={center}
                defaultZoom={12}
                gestureHandling="greedy"
                disableDefaultUI={false}
                onClick={(e) => {
                  if (e.detail.latLng) {
                    setCenter({
                      lat: e.detail.latLng.lat,
                      lng: e.detail.latLng.lng,
                    });
                    console.log("Clicked at:", e.detail.latLng);
                  }
                }}
              >
                {/* Render markers */}
                {showVenues &&
                  location?.venues.length > 0 &&
                  location?.venues?.map((marker: Venue) => (
                    <AdvancedMarker
                      key={marker.id}
                      position={{
                        lat: marker?.latitude || 0,
                        lng: marker?.longitude || 0,
                      }}
                    >
                      <div
                        style={{
                          background: "white",
                          padding: "5px",
                          borderRadius: "5px",
                          fontSize: "12px",
                        }}
                      >
                        <FaBuilding size={30} color="red" />
                        {marker?.title}
                      </div>
                    </AdvancedMarker>
                  ))}

                {showAdmin &&
                  location?.users.length > 0 &&
                  location?.users.filter((marker:User) => marker?.user_type_id === 2)
                    .map((marker: User) => (
                      <AdvancedMarker
                        key={marker.id}
                        position={{
                          lat: Number(marker?.latitude) || 0,
                          lng: Number(marker?.longitude) || 0,
                        }}
                      >
                        <div
                          style={{
                            background: "white",
                            padding: "5px",
                            borderRadius: "5px",
                            fontSize: "12px",
                          }}
                        >
                          <RiAdminFill size={30} color="blue" />
                          {marker?.username}
                        </div>
                      </AdvancedMarker>
                    ))}

{showAdmin &&
                  location?.users.length > 0 &&
                  location?.users.filter((marker:User) => marker?.user_type_id === 3)
                    .map((marker: User) => (
                      <AdvancedMarker
                        key={marker.id}
                        position={{
                          lat: Number(marker?.latitude) || 0,
                          lng: Number(marker?.longitude) || 0,
                        }}
                      >
                        <div
                          style={{
                            background: "white",
                            padding: "5px",
                            borderRadius: "5px",
                            fontSize: "12px",
                          }}
                        >
                          <RiAdminFill size={30} color="green" />
                          {marker?.username}
                        </div>
                      </AdvancedMarker>
                    ))}
              </Map>
            </div>
          </APIProvider>
        </Stack>
      </div>
    </>
  );
};
