import { ButtonData } from '../../../../../components/buttonGroup/ButtonGroup';
import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { isGlobal } from '../../../../../utils/consts';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { Scopes } from '../../../../../services/endpoints/people/user';
import { currentUserHasRole } from '../../../../../services/helper';
import { trainingVideo } from '../../../../../types/trainingVideos.type';
import { trainingVideosColumns } from '../../../../../utils/consts/columnDefs/trainingVideos';
import { useGetContentVideosQuery, useLazyGetContentVideoSyncQuery } from '../../../../../services/endpoints/content/contentVideo';
import { contentVideosColumns } from '../../../../../utils/consts/columnDefs/contentVideos';

const defaultScope = 'all';

export const ContentVideosView = () => {
  const { addToast } = useToast();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope, data: {page: 1, entries: 10} });
  const [scopes, setScopes] = useState<Scopes>({});
  const { data, error, isLoading, isFetching } = useGetContentVideosQuery(filters);
  const [trainingVideo, setTrainingVideo] = useState<trainingVideo[]>([]);
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const navigate: NavigateFunction = useNavigate();
  const [triggerSync, { data: syncData, error: syncError, isLoading: syncLoading }] = useLazyGetContentVideoSyncQuery();
  const [syncTrigger, setSyncTrigger] = useState(false); // Local state to trigger the sync query

  const filterFormFields: FilterField[] = [
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'id',
      label: 'Id',
    },
    {
      name: 'vimeo_id',
      label: 'Vimeo id',
    },
  ];

  useEffect(() => {
    if (!error && !isLoading && data && data.results) {
      
      if (data.scopes) {
        setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({...acc, ...curr}), {} as Scopes));
        setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => ({
          title: title,
          key: scope,
        }))));
      }
    } else if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
       
        setTrainingVideo(data.results);
    }
  }, [data]);

  
    useEffect(() => {
      if (!syncError && !syncLoading && syncData && syncData.results) {
        navigate('/admin/content/videos')
        //navigate to current page

      } else if (syncError) {
        addToast('Error while sync data', 'error');
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[syncData, syncError, syncLoading]);


  // Button handler to trigger sync
  const handleSyncVideos = () => {
    triggerSync();
  };

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`${scopes[filters?.scope!] ?? ''} Videos`}
        columns={contentVideosColumns}
        data={trainingVideo}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
        newItemEl={(
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={300}
            onClick={handleSyncVideos}
          >
            Sync with vimeo
          </SimpleButton>
        )}
      />
    </div>
  );
}
