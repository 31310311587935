import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { BackButton } from '../../../../../components/buttons/BackButton';
import Stepper from '../../../../../components/stepper/Stepper';
import { Formik, Form as FormikForm, FormikErrors, FormikTouched, FormikProps } from 'formik';
import { FadeIn } from '../../../../animations/FadeIn';
import * as Yup from "yup";
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { SessionDetailsForm } from './NewSessionSteps/SessionDetailsForm';
import { VenueForm } from './NewSessionSteps/VenueForm';
import { InstructorDetailsForm } from './NewSessionSteps/InstructorDetailsForm';
import { AdditionalInfoForm } from './NewSessionSteps/AdditionalInfoForm';
import { ScheduleForm } from './NewSessionSteps/ScheduleForm';
import { ClassesForm } from './NewSessionSteps/ClassesForm';
import { DocumentsAndFilesForm } from './NewSessionSteps/DocumentsAndFilesForm';
import { ReviewStep } from './NewSessionSteps/ReviewStep';
import { convertDaysToFull, getErrorMessage } from '../../../../../utils/utils';
import { AddCourseFormValues, useCreateCourseMutation, useGetCourseQuery, useUpdateCourseMutation } from '../../../../../services/endpoints/schedule/course';
import { useToast } from '../../../../../context/ToastContext';
import { useNavigate, useParams } from 'react-router-dom';
import { dateFromString, dateToUTC, formatDate, UTCToDate } from '../../../../../utils/dateUtils';
import { userCanEditCreateDelete_Sessions } from './SessionsView';
import { getCurrentUser } from '../../../../../services/helper';

const steps = [
  { name: 'Session Details', form: SessionDetailsForm },
  { name: 'Venue', form: VenueForm },
  { name: 'Instructor', form: InstructorDetailsForm },
  { name: 'Additional Info', form: AdditionalInfoForm },
  { name: 'Schedule', form: ScheduleForm },
  { name: 'Classes', form: ClassesForm },
  { name: 'Documents & Files', form: DocumentsAndFilesForm },
  { name: 'Review', form: ReviewStep },
];

interface ClassValues {
  id?: number;
  date: string;
  starts_at: string;
  ends_at: string;
  notes?: string;
  lesson_plan_ids?: { id: string, label: string }[];
  instructor_ids?: { id: string, label: string }[];
  trainee_ids?: { id: string, label: string }[];
  observer_ids?: { id: string, label: string }[];
  destroy: string;
}

interface FileMetadata {
  title: string;
}

export interface FormValues {
  id?: number;
  title: string;
  is_online: string;
  video_url?: string;
  payment_type: string;
  invoice_type?: string;
  price_per_class: string;
  can_be_prorated?: boolean;
  course_type: string;
  theme_id: string;
  status: string;
  venue_id: { value?: number, label: string };
  is_hidden: boolean;
  opts_out_of_roster_emails: boolean;
  room: string;
  capacity: number;
  instructor_ids: { id: string, label: string }[];
  min_instructors: number;
  instructor_notes: string;
  pay_type: string;
  flat_rate?: string;
  admin_notes: string;
  description: string;
  registered_information: string;
  day_of_week: string[];
  starts_at: string;
  ends_at: string;
  starts_on?: string;
  ends_on?: string;
  no_class?: string[];
  registration_starts_on?: string;
  registration_starts_at?: string;
  registration_ends_on?: string;
  registration_ends_at?: string;
  classes: ClassValues[];
  document_ids?: { id: string, label: string }[];
  files?: File[];
  file_metadata?: FileMetadata[];
}

export interface StepProps {
  errors: Partial<FormikErrors<FormValues>>;
  touched: Partial<FormikTouched<FormValues>>;
  values?: FormValues;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  readOnly?: boolean;
}

const validationSchemas = [
  Yup.object({
    title: Yup.string().required('Title is required'),
    is_online: Yup.string().required(),
    video_url: Yup.string()
      .when('is_online', (is_online, schema) => {
        if (is_online[0] === 'online') {
          return schema.required('Online class link is required');
        }
        return schema;
      }),
    payment_type: Yup.string().required(),
    invoice_type: Yup.string()
      .when('payment_type', (payment_type, schema) => {
        if (payment_type[0] === 'onsite') {
          return schema.required('Program type is required');
        }
        return schema;
      }),
    price_per_class: Yup.string().required('Tuition price is required'),
    can_be_prorated: Yup.boolean()
      .when('payment_type', (payment_type, schema) => {
        if (payment_type[0] === 'website') {
          return schema.required();
        }
        return schema;
      }
    ),
    course_type: Yup.string().required(),
    theme_id: Yup.string().required('Theme selection is required'),
    status: Yup.string().required(),
    is_hidden: Yup.boolean().required(),
    opts_out_of_roster_emails: Yup.boolean().required(),
  }),
  Yup.object({
    venue_id:  Yup.object({value: Yup.number().required('Venue selection is required'), label: Yup.string().optional()}).required(),
    room: Yup.string().required('Room is required'),
    capacity: Yup.number()
      .required('Capacity is required')
      .positive('Value must be a positive number'),
  }),
  Yup.object({
    min_instructors: Yup.number()
      .required('Number of instructors is required')
      .positive('Value must be a positive number'),
    instructor_ids: Yup.array().max(Yup.ref('min_instructors'), 'Main instructors selected exceeds limit above').optional(),
    pay_type: Yup.string().required('Pay type is required'),
    flat_rate: Yup.number()
      .when('pay_type', (pay_type, schema) => {
        if (pay_type[0] === 'flat_rate') {
          return schema.required('Flat Daily Rate is required');
        }
        return schema;
      }),
    instructor_notes: Yup.string().required('Instructor notes are required'),
  }),
  Yup.object({
    admin_notes: Yup.string().optional(),
    description: Yup.string().optional(),
    registered_information: Yup.string().optional(),
  }),
  Yup.object({
    day_of_week: Yup.array().min(1, 'Select at least one day'),
    starts_at: Yup.string().required('Start time is required'),
    ends_at: Yup.string().required('End time is required'),
    starts_on: Yup.string().required('Start date is required'),
    ends_on: Yup.string().required('End date is required'),
    no_class: Yup.array().optional(),
    registration_starts_on: Yup.string().optional(),
    registration_starts_at: Yup.string().optional(),
    registration_ends_on: Yup.string().optional(),
    registration_ends_at: Yup.string().optional(),
  }),
  Yup.object({
    classes: Yup.array().of(
      Yup.object().shape({
        date: Yup.string().required('Date is required'),
        starts_at: Yup.string().required('Start time is required'),
        ends_at: Yup.string().required('End time is required'),
        notes: Yup.string().optional(),
        lesson_plan_ids: Yup.array(),
        instructor_ids: Yup.array(),
        trainee_ids: Yup.array(),
        observer_ids: Yup.array(),
      }),
    ).min(1, 'Add at least one class'),
  }),
  Yup.object({
    document_ids: Yup.array().optional(),
    files: Yup.array().optional(),
    file_metadata: Yup.array(),
  }),
];

interface AddEditSessionProps {
  editing?: boolean;
}

export const AddEditSession: React.FC<AddEditSessionProps> = ({ editing }) => {
  const params = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const [createCourse] = useCreateCourseMutation();
  const [updateCourse] = useUpdateCourseMutation();
  const { data, error, isLoading }  = useGetCourseQuery(Number(params.id!), { skip: !editing || !params.id });
  const { addToast } = useToast();
  const navigate = useNavigate();
  const currentUser = getCurrentUser();

  const [initialValues, setInitialValues] = useState<FormValues>({
    title: '',
    is_online: '',
    payment_type: '',
    price_per_class: '',
    can_be_prorated: false,
    course_type: '',
    theme_id: '',
    status: '',
    venue_id: { label: '' },
    is_hidden: false,
    opts_out_of_roster_emails: false,
    room: '',
    capacity: 0,
    instructor_ids: [],
    min_instructors: 1,
    instructor_notes: '',
    admin_notes: '',
    description: '',
    registered_information: '',
    pay_type: '',
    day_of_week: [],
    starts_at: '',
    ends_at: '',
    starts_on: '',
    ends_on: '',
    classes: [],
    document_ids: [],
    files: [],
    file_metadata: [],
  });

  useEffect(() => {
    if (editing && error && !isLoading) {
      addToast('Error while loading session', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editing, error, isLoading]);

  useEffect(() => {
    if (data && data.course) {
      const { course } = data;
      const instructor_ids = course.course_assignments.map((item) => ({
        id: String(item.id),
        label: `${item.instructor?.first_name} ${item.instructor?.last_name}`,
      }));
      const day_of_week = course.day_of_week.split(', ').map((day) => day.substring(0, 3));
      const startDate = UTCToDate(`${course.lessons[0].date} ${course.starts_at}`, 'yyyy-MM-dd hh:mm a');
      const endDate = UTCToDate(`${course.lessons.at(-1)!.date} ${course.ends_at}`, 'yyyy-MM-dd hh:mm a');
      const classes: ClassValues[] = course.lessons.map((lesson, idx) => {
        let times = lesson.formatted_time.split(' - ').map(t => `${t.slice(0, -2)} ${t.slice(-2)}`)
        const start = UTCToDate(`${lesson.date} ${lesson.starts_at ?? times[0]}`, 'yyyy-MM-dd hh:mm a');
        const end = UTCToDate(`${lesson.date} ${lesson.ends_at ?? times[1]}`, 'yyyy-MM-dd hh:mm a');
        return {
          id: lesson.id,
          date: formatDate(start, 'yyyy-MM-dd'),
          starts_at: formatDate(start, 'HH:mm'),
          ends_at: formatDate(end, 'HH:mm'),
          notes: lesson.notes ?? undefined,
          lesson_plan_ids: lesson.lesson_plans.map((plan) => ({
            id: String(plan.id),
            label: plan.name,
          })),
          instructor_ids: lesson.lesson_assignments.filter((item) => item.instructor_role === 'instructor').map((assignemnt) => ({
            id: String(assignemnt.id),
            label: `${assignemnt.instructor?.first_name} ${assignemnt.instructor?.last_name}`
          })),
          trainee_ids: lesson.lesson_assignments.filter((item) => item.instructor_role === 'trainee').map((assignemnt) => ({
            id: String(assignemnt.id),
            label: `${assignemnt.instructor?.first_name} ${assignemnt.instructor?.last_name}`
          })),
          observer_ids: lesson.lesson_assignments.filter((item) => item.instructor_role === 'observer').map((assignemnt) => ({
            id: String(assignemnt.id),
            label: `${assignemnt.instructor?.first_name} ${assignemnt.instructor?.last_name}`
          })),
          destroy: 'false',
        }
      });
      const document_ids = course.documents.map((doc) => ({
        id: String(doc.id),
        label: doc.title,
      }));
      setInitialValues({
        id: course.id,
        title: course.title,
        is_online: course.is_online ? 'online' : 'in_person',
        ...(course.is_online && { video_url: course.video_url }),
        payment_type: course.payment_type,
        ...(course.payment_type === 'onsite' && { invoice_type: course.invoice_type }),
        price_per_class: String(course.price_per_class),
        ...(course.payment_type === 'website' && { can_be_prorated: course.can_be_prorated }),
        course_type: course.course_type,
        theme_id: String(course.theme_id),
        status: course.status,
        venue_id:  {
          value: course.venue_id,
          label: course.venue?.title ? `${course.venue?.title}` : ''
        },
        is_hidden: course.is_hidden,
        opts_out_of_roster_emails: course.opts_out_of_roster_emails,
        room: course.room,
        capacity: course.capacity,
        instructor_ids,
        min_instructors: course.min_instructors,
        instructor_notes: course.instructor_notes,
        admin_notes: course.admin_notes,
        description: course.description,
        registered_information: course.registered_information,
        pay_type: course.pay_type,
        ...(course.pay_type === 'flat_rate' && { flat_rate: course.flat_rate }),
        day_of_week,
        starts_at: formatDate(startDate, 'HH:mm'),
        ends_at: formatDate(endDate, 'HH:mm'),
        starts_on: formatDate(startDate, 'yyyy-MM-dd'),
        ends_on: formatDate(endDate, 'yyyy-MM-dd'),
        classes,
        document_ids,
        files: [],
        file_metadata: [],
      })
    }

    if(!userCanEditCreateDelete_Sessions(currentUser)) {
      addToast('You have no permissions to edit this data', 'error');
      navigate(`/admin/schedule/sessions`);
    }
  }, [data]);


  const handleRegister = async (formValues: FormValues) => {
    const classes = formValues.classes.map((value) => {
      const startDate = dateFromString(`${value?.date} ${value?.starts_at}`, 'yyyy-MM-dd HH:mm');
      const endDate = dateFromString(`${value?.date} ${value?.ends_at}`, 'yyyy-MM-dd HH:mm');
      return {
        ...value,
        date: dateToUTC(startDate, 'yyyy-MM-dd'),
        starts_at: dateToUTC(startDate, 'hh:mm a'),
        ends_at: dateToUTC(endDate, 'hh:mm a'),
        lesson_plan_ids: value.lesson_plan_ids && value.lesson_plan_ids.length > 0 ? value.lesson_plan_ids?.map((l) => l.id) : undefined,
        instructor_ids: value.instructor_ids && value.instructor_ids.length > 0 ? value.instructor_ids.map((i) => i.id) : undefined,
        trainee_ids: value.trainee_ids && value.trainee_ids.length > 0 ? value.trainee_ids.map((i) => i.id) : undefined,
        observer_ids: value.observer_ids && value.observer_ids.length > 0 ? value.observer_ids.map((i) => i.id) : undefined,
      }
    }).sort((a, b) => a.date.localeCompare(b.date));

    const dayOfWeek = convertDaysToFull(formValues.day_of_week, formValues.starts_on!, formValues.ends_on!);
    const startDate = dateFromString(`${formValues.starts_on} ${formValues.starts_at}`, 'yyyy-MM-dd HH:mm');
    const endDate = dateFromString(`${formValues.ends_on} ${formValues.ends_at}`, 'yyyy-MM-dd HH:mm');
    let startDate_registration;
    let endDate_registration;
    const { registration_starts_on, registration_starts_at, registration_ends_on, registration_ends_at } = formValues;

     startDate_registration =registration_starts_on && registration_starts_at ? dateFromString(`${formValues.registration_starts_on} ${formValues.registration_starts_at}`, 'yyyy-MM-dd HH:mm') : null;
     endDate_registration= registration_ends_on && registration_ends_at ? dateFromString(`${formValues.registration_ends_on} ${formValues.registration_ends_at}`, 'yyyy-MM-dd HH:mm') : null;


   
    const schedulePayload: AddCourseFormValues = {
      ...formValues,
      ...(editing && { id: data?.course.id }),
      is_online: formValues.is_online === 'online',
      can_be_prorated: formValues.payment_type === 'website' ? formValues.can_be_prorated : undefined,
      is_hidden: String(formValues.is_hidden),
      opts_out_of_roster_emails: String(formValues.opts_out_of_roster_emails),
      capacity: String(formValues.capacity),
      min_instructors: String(formValues.min_instructors),
      day_of_week: dayOfWeek,
      starts_at: dateToUTC(startDate, 'hh:mm a'),
      ends_at: dateToUTC(endDate, 'hh:mm a'),
      instructor_ids: Array.isArray(formValues.instructor_ids) 
      ? formValues.instructor_ids.map((i) => i.id) 
      : typeof formValues.instructor_ids === "string" 
        ? [formValues.instructor_ids] 
        : [],
      no_class: formValues.no_class && formValues.no_class.length > 0 ? formValues.no_class.join(', ') : undefined,
      classes,
      document_ids: formValues.document_ids && formValues.document_ids.length > 0 ? formValues.document_ids.map((doc) => doc.id) : undefined,
      files: formValues.files && formValues.files.length > 0 ? formValues.files : undefined,
      file_metadata: formValues.file_metadata && formValues.file_metadata.length > 0 ? formValues.file_metadata : undefined,
      ...(startDate_registration && {registration_starts_at:dateToUTC(startDate_registration, 'hh:mm a')} ),
      ...(endDate_registration && {registration_ends_at:dateToUTC(endDate_registration, 'hh:mm a') }),
      venue_id:String(formValues.venue_id.value) ?? ''
    };

    // Fields calculated in backend, but needed in the form
    delete schedulePayload.starts_on;
    delete schedulePayload.ends_on;
   

    try {
      if (editing) {
        const res = await updateCourse(schedulePayload).unwrap();
        if (res && res.course) {
          navigate(`/admin/schedule/sessions/${res.course.id}`);
          addToast('Session updated succesfully', 'success');
        }
      } else {
        const res = await createCourse(schedulePayload).unwrap();
        if (res && res.course) {
          navigate(`/admin/schedule/sessions/${res.course.id}`);
          addToast('Session created succesfully', 'success');
          return;
        } else throw new Error('A problem happened while creating session');
      }
    } catch (e) {
      addToast(getErrorMessage(e), 'error');
    }
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FormValues>>,
    setTouched: (touched: FormikTouched<FormValues>, shouldValidate?: boolean) => void,
    values: FormValues) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      values.classes.sort((a, b) => a.date.localeCompare(b.date));
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        title: true,
        is_online: true,
        video_url: true,
        payment_type: true,
        price_per_class: true,
        can_be_prorated: true,
        course_type: true,
        theme_id: true,
        status: true,
        venue_id: { value: true },
        room: true,
        capacity: true,
        instructor_ids: [],
        instructor_notes: true,
        pay_type: true,
        flat_rate: true,
        admin_notes: true,
        description: true,
        registered_information: true,
        day_of_week: true,
        starts_at: true,
        ends_at: true,
        starts_on: true,
        ends_on: true,
        classes: [],
      });
    }
  };

  return (
    <FadeIn className="p-lg-4">
      <Stack gap={3} className="py-2 py-lg-0 px-4">
        <div>
          <BackButton
            text={`Back to session${editing ? ' details' : 's'}`}
            color="#2B4E64"
            url={editing ? `/admin/schedule/sessions/${data?.course.id}` : '/admin/schedule/sessions'}
          />
          <h2
            className="d-none d-lg-block text-info fw-bold mb-0"
            style={{ fontSize: '2.25rem' }}
          >
            {editing ? 'Edit' : 'New'} Session
          </h2>
          <Stepper steps={steps} currentStep={currentStep} editing={editing === true} onStepClick={(step) => setCurrentStep(step)} />
        </div>

        <div
          className="p-3 p-lg-5"
          style={{
            boxShadow: '0 4px 8px -2px rgba(16, 24, 40, .1),  0 4px 4px 0 rgba(0, 0, 0, .25)',
            border: '1px solid #EBEBEB',
            borderRadius: 10,
          }}
        >
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[currentStep]}
              onSubmit={handleRegister}
              enableReinitialize
            >
              {({ submitForm, isSubmitting, validateForm, touched, errors, setTouched, values, setFieldValue,  }: FormikProps<FormValues>) => (
                <FormikForm className="text-start">
                  <div style={{ minHeight: '65vh' }}>
                    {currentStep < steps.length
                      && React.createElement(steps[currentStep]?.form, {
                        errors,
                        touched,
                        values,
                        setFieldValue,
                      })
                    }
                  </div>

                  <Row className="justify-content-end mt-3">
                    <Col xs={6} lg={3}>
                      {currentStep !== 0 && (
                        <SimpleButton
                          disabled={isSubmitting}
                          type="button"
                          onClick={() => setCurrentStep(currentStep - 1)}
                          className="w-100"
                          variant="outline-primary"
                        >
                          Back
                        </SimpleButton>
                      )}
                    </Col>

                    <Col xs={6} lg={3}>
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={currentStep === steps.length - 1 ? submitForm : () => handleNext(validateForm, setTouched, values)}
                        className="w-100"
                        variant="primary"
                      >
                        {currentStep === steps.length - 1 ? 'Finish' : 'Continue'}
                      </SimpleButton>
                    </Col>
                      
                    {(editing && currentStep !== steps.length - 1) && 
                    <Col xs={6} lg={3}>
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={ submitForm }
                        className="w-100"
                        variant="primary"
                      >
                        Save & Close
                      </SimpleButton>
                    </Col>      
                    }
                  </Row>
                </FormikForm>
              )}
            </Formik>
        </div>
      </Stack>
    </FadeIn>
  );
};
