import { ChangeEvent, SetStateAction, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SimpleButton } from "../../../../../components/buttons/SimpleButton";
import { FaArrowLeft } from "react-icons/fa6";
import { Accordion, Button, FormControl, Spinner, Stack } from "react-bootstrap";
import { SFCAccordion } from "../../../../../components/accordion/SFCAccordion";
import { SimpleTable } from "../../../../../components/tables/SimpleTable";
import {
  useGetCourseQuery,
  useUpdateEmailsMutation,
} from "../../../../../services/endpoints/schedule/course";
import { useToast } from "../../../../../context/ToastContext";
import { IconLink } from "../../../../../components/iconLink/IconLink";
import {
  BsCalendar,
  BsClock,
  BsEnvelope,
  BsFiletypePdf,
  BsFillGeoAltFill,
  BsFillTelephoneFill,
  BsGlobe,
} from "react-icons/bs";
import { ReactComponent as LedgerIcon } from "../../../../../assets/icons/ledger.svg";
import Form from "react-bootstrap/Form";

import {
  enrollmentColumns,
  revisionsColumns,
  rosterColumns,
  getScheduleColumns,
  sessionExpensesColumns,
  waitlistColumns,
  waiversColumns,
} from "../../../../../utils/consts/columnDefs/sessionColumns";
import StatusIcon from "../../../../../components/status/StatusIcon";
import { Course } from "../../../../../types/course.type";
import { DateCell } from "../../../../../components/tables/cellComponents/DateCell";
import { DetailSection, LabelText } from "../../../../../components/generic";
import {
  useCreateSubRequestMutation,
  useDeleteSubRequestMutation,
  useRenegClassMutation,
} from "../../../../../services/endpoints/schedule/lessonAssignment";
import { useGetThemeQuery } from "../../../../../services/endpoints/content/theme";
import { getCurrentUser } from "../../../../../services/helper";
import SafeHTMLDisplay from "../../../../common/SafeHTMLDisplay";
import { LessonPlansCell } from "../../../../../components/tables/cellComponents/LessonPlansCell";
import {
  userCanEditCreateDelete_Sessions,
  userCanGenerateInvoice_Sessions,
} from "./SessionsView";
import { ErrorMessage, Field } from 'formik';
import { FileSelect } from "../../../../../components/inputs/FileSelect";
import { FormValues } from "../../finance/Receipts/AddEditReceipt";
import { useCreateReceiptMutation, useUpdateReceiptMutation } from "../../../../../services/endpoints/finance/receipt";
import { getErrorMessage } from "../../../../../utils/utils";

const currentUser = getCurrentUser();

const LabelTextList = ({ label, list }: { label: string; list: string[] }) => (
  <Stack direction="vertical" gap={1}>
    <span className="fw-semibold" style={{ color: "rgba(94, 100, 105, .5)" }}>
      {label}
    </span>
    {list.map((text, index) => (
      <span key={index} className="text-secondary fw-semibold">
        {text}
      </span>
    ))}
  </Stack>
);

export type lessonAssignmentActionsData = {
  id?: number;
  lesson_id?: number;
  requesting_instructor_id?: number;
  course_id?: number;
  location_id?: number;
};

export type lessonAssignmentActionsType = "reneg" | "create" | "delete";

export type lessonAssignmentActionFn = (
  action: lessonAssignmentActionsType,
  data: lessonAssignmentActionsData
) => void;

export const SessionDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetCourseQuery(+params.id!);
  const {
    data: theme,
    error: themeError,
    isLoading: themeLoading,
  } = useGetThemeQuery(data?.course.theme_id ?? 0, {
    skip: !data?.course.theme_id,
  });
  const [renegSubOffer] = useRenegClassMutation();
  const [createSubRequest] = useCreateSubRequestMutation();
  const [deleteSubRequest] = useDeleteSubRequestMutation();
  const [createReceipt] = useCreateReceiptMutation();
  const [updateReceipt] = useUpdateReceiptMutation();
  const [course, setCourse] = useState<Course>();
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [storeName, setStoreName] = useState<string>();
  const [dateOfPurchase, setDateOfPurchase] = useState<string>();
  const [totalReceipt, setTotalReceipt] = useState<number>();
  const [notesReceipt, setNotesReceipt] = useState<string>("");
  const [file, setFile] = useState<File>();
  const fileRef =useRef<HTMLInputElement | null>(null);
  
  const featured_tlps =
    theme?.theme?.lesson_plans?.filter(
      (lp) =>
        lp.theme_lesson_plans && lp.theme_lesson_plans.featured_on !== null
    ) ?? [];
  const [updaterEmails] = useUpdateEmailsMutation();
  const handleEmailText = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };
  useEffect(() => {
    if (error && !isLoading) {
      addToast("Error while loading session", "error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setCourse(data.course);

      setSelectedOption(data?.course?.emails);
    }
  }, [data]);



  const updateEmails = async () => {
    if (data?.course?.id && selectedOption) {
      const updateEmails = await updaterEmails({
        id_course: data?.course?.id,
        emails: selectedOption ?? "",
      }).unwrap();
      addToast(`Emails was updated.`, "success");
    }
  };

// const updateReceipt = async ()=>{
//   if (data?.course?.id && selectedOption) {

//   }
// }

  const actionCallback: lessonAssignmentActionFn = (action, data) => {
    // console.log(action, data);
    switch (action) {
      case "reneg":
        if (data.id) {
          renegSubOffer({ id: data.id })
            .unwrap()
            .then(console.log)
            .catch(console.error);
        }
        break;
      case "create":
        createSubRequest(data).unwrap().then(console.log).catch(console.error);
        break;
      case "delete":
        deleteSubRequest({ id: data.id })
          .unwrap()
          .then(console.log)
          .catch(console.error);
        break;
      default:
        break;
    }
  };

  if (isLoading) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    );
  }

  const sessionID = <LabelText label="Session ID:" text={course?.id} />;
  const chefPay =
    course?.pay_type === "flat_rate"
      ? `Flat Rate $${course?.flat_rate}/day`
      : "Hourly Rate";

  const prorated = course?.payment_type === "website" && (
    <LabelText label="PRORATED:">
      <StatusIcon value={course?.can_be_prorated} />
    </LabelText>
  );

const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  if (event.target.files && event.target.files[0]) {
    setFile(event.target.files[0]);
  }
  // console.log(file);
  }

   const handleSubmitReceipt = async () => {
    
      try {
        const receiptData = {
          store_name:storeName ?? '',
          total:totalReceipt ?? 0,
          course_id:course?.id ?? 0,
          image:file,
          purchased_at:dateOfPurchase
        };
        // if (editing) {
        //   const res = await updateReceipt(receiptData).unwrap();
        //   if (res && res.receipt) {
        //     addToast(`Receipt updated succesfully`, 'success');
        //     // navigate(`/admin/finance/receipts/${data?.receipt.id}`);
        //   }
        // } else {
          const res = await createReceipt(receiptData).unwrap();
          if (res && res.receipt) {
            // navigate(`/admin/finance/receipts/${res.receipt.id}`);
            
            addToast('Receipt created succesfully', 'success');
            window.location.reload();
          } else {
            
            addToast(getErrorMessage(res), 'error');
          }
        // }
      } catch (e) {
        addToast(getErrorMessage(e), 'error');
      }
    };

    

  const instructorsList = (
    <LabelText label="Instructors:">
      <Stack>
        {course?.course_assignments.map((ca) => (
          <Link
            key={ca.id}
            to={`/admin/dash-users/instructors/${ca.instructor_id}`}
            className="text-info fw-bold"
          >
            {ca.instructor?.first_name} {ca.instructor?.last_name}
          </Link>
        ))}
      </Stack>
    </LabelText>
  );

  const venueAddress = (
    <div>
      <IconLink
        icon={<BsFillGeoAltFill size={24} />}
        text={course?.venue.title}
      />
      <Stack className="text-info fw-semibold" style={{ marginLeft: 34 }}>
        <span>{course?.venue.address}</span>
        <span>{`${course?.venue.city} ${course?.venue.state}, ${course?.venue.zip}`}</span>
      </Stack>
    </div>
  );

  const tuition = (
    <LabelText
      label="Tuition:"
      direction="vertical"
      text={course?.tuition_summary_string}
    />
  );

  const detailsAccordion = (
    <SFCAccordion defaultActiveKey={["0", "1", "2", "3"]}>
      <Accordion.Item eventKey="0" className="d-lg-none">
        <Accordion.Header>Resources</Accordion.Header>
        <Accordion.Body>
          <DetailSection>
            <IconLink
              icon={<BsGlobe size={24} />}
              text="Website"
              url={`/sessions/${course?.id}`}
            />
            <IconLink
              icon={<LedgerIcon />}
              text="Summary Session Financials"
              url={`/admin/schedule/sessions/${course?.id}/ledger`}
            />
            <IconLink icon={<BsFiletypePdf size={24} />} text="Sign-In Sheet" />
            <IconLink icon={<BsFiletypePdf size={24} />} text="Roster" />
            <IconLink
              icon={<BsEnvelope size={24} />}
              text="Send Welcome Email"
            />
            <IconLink
              icon={<BsEnvelope size={24} />}
              text="Send Thank You Email"
            />
            <IconLink
              icon={<BsEnvelope size={24} />}
              text="Send Roster/Waiver"
            />
          </DetailSection>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="99">
        <Accordion.Header>Class Schedule</Accordion.Header>
        <Accordion.Body>
          <div className="d-none d-lg-block">
            <SimpleTable
              columns={getScheduleColumns(actionCallback)}
              data={
                course?.lessons?.map((lesson) => ({
                  ...lesson,
                  course,
                  featured_tlps,
                })) ?? []
              }
            />
          </div>

          {/* mobile */}
          <div className="d-lg-none ">
            {course?.lessons?.map((lessons, id) => (
              <div className="div-rounded" key={id}>
                <h2>
                  {" "}
                  <DateCell
                    date={lessons?.date}
                    time={lessons.starts_at ?? lessons.starts_at}
                    format="EEEE"
                  />{" "}
                  - <DateCell date={lessons?.date} />
                </h2>
                <div>
                  <DateCell
                    date={lessons?.date}
                    time={lessons.starts_at ?? lessons.starts_at}
                    format="hh:mm a"
                  />
                  {` - `}
                  <DateCell
                    date={lessons?.date}
                    time={lessons.ends_at ?? lessons.ends_at}
                    format="hh:mm a"
                  />
                </div>
                <h3>Lesson Plan</h3>
                {lessons.lesson_plans.map((lesson_plan, index_lesson) => (
                  <h3>{lesson_plan.name}</h3>
                ))}
                <h3>
                  {" "}
                  {lessons?.lesson_assignments[0]?.instructor?.full_name} - Main
                  Instructor
                </h3>
              </div>
            ))}
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <div className="d-none d-lg-block">
        <Accordion.Item eventKey="1">
          <Accordion.Header>Instructor Notes</Accordion.Header>
          <Accordion.Body>{course?.instructor_notes}</Accordion.Body>
        </Accordion.Item>

        {course?.is_online ? (
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Roster {`(${course?.enrollment})`}
            </Accordion.Header>
            <Accordion.Body>
              <Stack gap={3}>
                <Link to="" className="text-info">
                  Manage Registrations
                </Link>
                <SimpleTable
                  columns={rosterColumns}
                  data={
                    course?.registrations?.map((registration) => ({
                      ...registration?.child,
                      course,
                      featured_tlps,
                    })) ?? []
                  }
                />
              </Stack>
            </Accordion.Body>
          </Accordion.Item>
        ) : (
          <Accordion.Item eventKey="2">
            <Accordion.Header>Enrollment Tool</Accordion.Header>
            <Accordion.Body>
              <div className="d-none d-lg-block">
                <Stack gap={3}>
                  <SimpleTable
                    columns={enrollmentColumns}
                    data={
                      course?.enrollments?.map((enrollment) => ({
                        ...enrollment,
                        course,
                        featured_tlps,
                      })) ?? []
                    }
                  />
                </Stack>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        )}

        {course?.is_online ? (
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              Waitlist ({course?.children?.length})
            </Accordion.Header>
            <Accordion.Body>
              <SimpleTable
                columns={waitlistColumns}
                data={
                  course?.children?.map((child) => ({
                    ...child,
                    course,
                    featured_tlps,
                  })) ?? []
                }
              />
            </Accordion.Body>
          </Accordion.Item>
        ) : (
          <Accordion.Item eventKey="3">
            <Accordion.Header>Waivers</Accordion.Header>
            <Accordion.Body>
              <SimpleTable
                columns={waiversColumns}
                data={
                  course?.waivers?.map((waiver) => ({
                    ...waiver,
                    course,
                    featured_tlps,
                  })) ?? []
                }
              />
            </Accordion.Body>
          </Accordion.Item>
        )}
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            Additional Session Related Expenses
          </Accordion.Header>
          <Accordion.Body>
            <SimpleTable
              columns={sessionExpensesColumns}
              data={
                course?.expenses?.map((expense) => ({
                  ...expense,
                  course,
                  featured_tlps,
                })) ?? []
              }
            />
            <SimpleButton
              variant="primary"
              height={36}
              onClick={() =>
                navigate(`/admin/finance/expenses/new?course_id=${course?.id}`)
              }
            >
              New Expense
            </SimpleButton>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>Revisions</Accordion.Header>
          <Accordion.Body>
            <div className="d-none d-lg-block">
              <SimpleTable
                columns={revisionsColumns}
                data={
                  course?.audits?.map((audit) => ({
                    ...audit,
                    course,
                    featured_tlps,
                  })) ?? []
                }
              />
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </div>

      {/* mobile */}
      <div className="d-lg-none ">
        {!course?.is_online && (
          <Accordion.Item eventKey="3">
            <Accordion.Header>Waivers</Accordion.Header>
            <Accordion.Body>
              <div className="div-rounded">
                <h3>Parent Name</h3>
                <span>{course?.waivers?.[0]?.child_first_name}</span>
                <h3>Parent Email</h3>
                <span>{course?.waivers?.[0]?.parent_email_address}</span>

                <h3>Parent Phone</h3>
                <span>{course?.waivers?.[0]?.parent_mobile_number}</span>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        )}

        {!course?.is_online && (
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Roster {`(${course?.enrollment})`}
            </Accordion.Header>
            <Accordion.Body>
              {course?.registrations?.map((registration, id) => (
                <div className="div-rounded" key={id}>
                  <h1>{registration?.child?.full_name}</h1>
                  <h2>Allergies</h2>
                  <h2>Social / Emotional Needs</h2>
                  <h2>Parent info</h2>
                  {registration?.child?.parent.name} |{" "}
                  {registration?.child?.parent.email}
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        )}
      </div>
    </SFCAccordion>
  );

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: "#EFEFEF" }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button
            className="btn btn-link p-0 text-info"
            onClick={() => navigate("/admin/schedule/sessions")}
          >
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: "#888888", fontSize: "1rem" }}
          >
            Sessions List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">
            {course?.title}
          </h2>
        </Stack>
        <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
          {userCanEditCreateDelete_Sessions(currentUser) && (
            <SimpleButton
              variant="primary"
              height={36}
              onClick={() =>
                navigate(`/admin/schedule/sessions/${course?.id}/edit`)
              }
            >
              Edit session
            </SimpleButton>
          )}

          {userCanGenerateInvoice_Sessions(currentUser) && (
            <SimpleButton
              variant="primary"
              height={36}
              onClick={() =>
                navigate(`/admin/schedule/sessions/invoicer/${course?.id}`, {
                  state: { course: course },
                })
              }
            >
              Generate invoice
            </SimpleButton>
          )}
        </Stack>
      </div>
      <div className="d-lg-none">
        <Stack gap={4} className="p-4">
          <DetailSection>
            <span className="fs-5 text-info fw-bold">{course?.title}</span>
            {venueAddress}
            <IconLink icon={<BsCalendar size={24} />}>
              <span className="text-secondary fw-semibold">
                {course?.day_of_week}
              </span>
            </IconLink>
            <IconLink icon={<BsClock size={24} />}>
              <span className="text-secondary fw-semibold">
                {course?.starts_at} - {course?.ends_at}
              </span>
            </IconLink>
            {sessionID}
            <LabelText label="Theme:" text="Theme" />
            <LabelText label="Room:" text={course?.room} />
            <LabelText label="Enrollment:" text={course?.enrollment} />
            <LabelText
              label="Session Payment type:"
              text={course?.payment_type === "onsite" ? "On-site" : "Website"}
            />
            {tuition}
            <LabelText label="Chef Pay:" text={chefPay} />
            {prorated}
            {instructorsList}
          </DetailSection>

          {detailsAccordion}
        </Stack>
      </div>
      <div className="d-none d-lg-flex">
        <div
          className="mt-5 w-100 px-5 py-4"
          style={{ maxWidth: "calc(100% - 400px)" }}
        >
          {detailsAccordion}
        </div>
        <Stack
          gap={4}
          className="p-4"
          style={{ width: 400, background: "#F2F2F2" }}
        >
          <DetailSection title="Resources">
            <IconLink
              icon={<BsGlobe size={24} />}
              text="Website"
              url={`/sessions/${course?.id}`}
            />
            <IconLink
              icon={<LedgerIcon />}
              text="Summary Session Financials"
              url={`/admin/schedule/sessions/${course?.id}/ledger`}
            />
            <IconLink icon={<BsFiletypePdf size={24} />} text="Sign-In Sheet" />
            <IconLink icon={<BsFiletypePdf size={24} />} text="Roster" />
            <IconLink
              icon={<BsEnvelope size={24} />}
              text="Send Welcome Email"
            />
            <IconLink
              icon={<BsEnvelope size={24} />}
              text="Send Thank You Email"
            />
            <IconLink
              icon={<BsEnvelope size={24} />}
              text="Send Roster/Waiver"
            />
          </DetailSection>

          <DetailSection title="Details">
            {venueAddress}
            <IconLink
              icon={<BsFillTelephoneFill size={24} />}
              text={course?.venue.phone}
            />
          </DetailSection>

          <DetailSection title="Details">
            {sessionID}
            <LabelText label="Theme:" text="Theme" />
            <LabelText label="Room:" text={course?.room} />
            <LabelText label="Time:">
              <DateCell
                date={course?.lessons?.[0]?.date}
                time={course?.starts_at}
                format="h:mm a"
              />
              {" - "}
              <DateCell
                date={course?.lessons?.[0]?.date}
                time={course?.ends_at}
                format="h:mm a"
              />
            </LabelText>
            <LabelText label="Enrollment:" text={course?.enrollment} />
            <LabelText
              label="Session Payment type:"
              text={course?.payment_type === "onsite" ? "On-site" : "Website"}
            />
            {tuition}
            <LabelText label="Chef Pay:" text={chefPay} />
            {prorated}
            {instructorsList}
            <LabelText label="Organizations:" direction="vertical">
              {course?.venue?.organizations?.length ? (
                <Stack>
                  {course.venue.organizations.map((org) => (
                    <Link key={org.id} to="" className="text-info fw-bold">
                      {org.name}
                    </Link>
                  ))}
                </Stack>
              ) : (
                <span className="fst-italic">
                  No organizations are assigned to this venue
                </span>
              )}
            </LabelText>
          </DetailSection>

          {currentUser?.isAdmin && (
            <DetailSection title="Notes">
              {course?.venue &&
                (course?.venue.organizations.length ?? 0) > 0 &&
                course?.venue.organizations.map((org, idx) => (
                  <LabelText key={idx} label={`${org.name} Notes`}>
                    <SafeHTMLDisplay
                      htmlContent={org.notes || "<em>No notes to show</em>"}
                    />
                  </LabelText>
                ))}
              <LabelText label="Venue Notes">
                {!currentUser.isInstructor ? (
                  <SafeHTMLDisplay
                    htmlContent={
                      course?.venue?.notes || "<em>No notes to show</em>"
                    }
                  />
                ) : (
                  <em>No notes to show.</em>
                )}
              </LabelText>
              <LabelText label="Facility Use Fee Payment Details">
                {!currentUser.isInstructor ? (
                  <SafeHTMLDisplay
                    htmlContent={
                      course?.venue?.facility_use_fee_payment_details ||
                      "<em>No notes to show</em>"
                    }
                  />
                ) : (
                  <em>No notes to show.</em>
                )}
              </LabelText>
              <LabelText label="Admin Session Notes">
                <SafeHTMLDisplay
                  htmlContent={
                    course?.admin_notes || "<em>No notes to show</em>"
                  }
                />
              </LabelText>
            </DetailSection>
          )}

          <DetailSection title="Contacts">
            {course?.venue?.contacts?.length ? (
              course.venue.contacts.map((contact, idx) => (
                <LabelTextList
                  key={idx}
                  label={`${contact.full_name}:`}
                  list={[contact.email]}
                />
              ))
            ) : (
              <span className="fst-italic">There are no contacts to show.</span>
            )}
          </DetailSection>

          {/* TODO: check invoices field and fill the info here */}
          <DetailSection title="Invoices">
            <LabelText label="Amount Receivable:" text="$0.00" />
            <Stack direction="horizontal" gap={2}>
              <IconLink
                icon={<BsFiletypePdf size={24} />}
                text="Bust Your Burro Cooking 2023 Camp Parent-based Invoice"
              />
              <span
                style={{
                  background: "#6098B8",
                  color: "#FFFFFF",
                  fontSize: ".75rem",
                }}
                className="rounded-4 py-1 px-2 fw-semibold"
              >
                Paid
              </span>
            </Stack>

            <Stack direction="horizontal" gap={2}>
              <IconLink
                icon={<BsFiletypePdf size={24} />}
                text="Bust Your Burro Cooking 2024 Camp Parent-based Invoice"
              />
              <span
                style={{
                  background: "#F7AB1B",
                  color: "#FFFFFF",
                  fontSize: ".75rem",
                }}
                className="rounded-4 py-1 px-2 fw-semibold"
              >
                Sent
              </span>
            </Stack>
          </DetailSection>

          <DetailSection title="Parent Emails">
            {/* <LabelTextList label="Clementine Test:" list={['colorado@email.com','colorado@email.com','colorado@email.com','colorado@email.com']} /> */}
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Roster Emails</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  onChange={handleEmailText}
                  value={selectedOption}
                />
              </Form.Group>
              <Button onClick={updateEmails}>Update emails</Button>
            </Form>
            <Form>
              <Form.Label>Waitlist Emails</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={data?.course?.course_waitlist_emails}
              />
            </Form>
          </DetailSection>

          {data?.course?.invoice_type && (
            <DetailSection title="SFC Corporate Credit Card Receipts">
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Store Name</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e)=>setStoreName(e.target.value)}
                    value={storeName}
                  />
                </Form.Group>
              </Form>
              <Form>
                <Form.Label>Date of Purchase</Form.Label>
                <Form.Control
                  type="date"
                  value={dateOfPurchase}
                  onChange={(e)=>setDateOfPurchase(e.target.value)}
                />
              </Form>
              <Form>
                <Form.Label>Total</Form.Label>
                <Form.Control
                  type="number"
                  value={totalReceipt}
                  onChange={(e)=>setTotalReceipt(Number(e.target.value))}
                />
              </Form>
              <Form>
              <Form.Label>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={notesReceipt}
                onChange={(e)=>setNotesReceipt(e.target.value)}
                placeholder="Please Include detailed notes here explaining source of cost and need for reimbursement."

              />
              <Form.Group className="mb-1 mb-lg-3" controlId="total">
          <Form.Label>Upload Image</Form.Label>
          <Form.Label>Image</Form.Label>
          <Form.Control
              name='image'
              ref={fileRef}
              className="form-control"
              accept="*"
              type="file"
              onChange={handleFileChange}
            />
          </Form.Group>
              </Form>
              <Button onClick={handleSubmitReceipt}>Create Receipt</Button>

            </DetailSection>
          )}
        </Stack>
      </div>
    </>
  );
};
