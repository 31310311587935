import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { BackButton } from '../../../../../components/buttons/BackButton';
import Stepper from '../../../../../components/stepper/Stepper';
import { Formik, Form as FormikForm, FormikErrors, FormikTouched, FormikProps } from 'formik';
import { FadeIn } from '../../../../animations/FadeIn';
import * as Yup from "yup";
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { getErrorMessage, getNestedTouchedTrue, getObjectDifference } from '../../../../../utils/utils';
import { useToast } from '../../../../../context/ToastContext';
import { useNavigate, useParams } from 'react-router-dom';
import { phoneRegExp } from '../../../../../utils/consts';
import { Address, convertContactImage, convertLocationImages, formatResponse, LocationImage, SFCLocation, SFCLocationResponse } from '../../../../../types/place.types';
import { useCreateLocationMutation, useGetLocationQuery, useUpdateLocationMutation } from '../../../../../services/endpoints/places/location';
import { LocationForm } from './LocationForm';
import { userCanEdit } from './LocationsView';
import { getCurrentUser } from '../../../../../services/helper';

const steps = [
  {name: 'Details', form: LocationForm},
];

export interface FormValues {
  id?: number;
  title: string;
  business_entity_name?: string;
  phone_number: string;
  primary_city: string;
  manager_id?: {value: number, label: string};
  scheduling_email: string;
  invoicing_email: string;
  hotline?: string;
  tax_id_number?: string;
  is_active: boolean;
  addresses: Address[];
  latitude: number;
  longitude: number;
  zoom: number;

  location_information_facebook: string;
  location_information_instagram: string;
  location_information_who_we_are: string;
  location_information_franchise_owner_first_name: string;
  location_information_franchise_owner_last_name: string;
  location_information_franchise_owner_email: string;
  location_information_jobs_url: string;

  images?: File[];
  franchise_images?: File[];
  contact_image?: File;
}

type Overwrite<T, NewT> = Omit<T, keyof NewT> & NewT;

export type CreateFormValues = Overwrite<FormValues, {
  manager_id: number,
  location_information: {
    facebook: string;
    instagram: string;
    who_we_are: string;
    franchise_owner_first_name: string;
    franchise_owner_last_name: string;
    franchise_owner_email: string;
    jobs_url: string;
  }
}>

export interface StepProps {
  errors: Partial<FormikErrors<FormValues>>;
  touched: Partial<FormikTouched<FormValues>>;
  values?: FormValues;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  readOnly?: boolean;
  editing?: boolean;
}

export const RemittanceAddressSchema = Yup.object({
  street: Yup.string().required('Street is required'),
  unit: Yup.string().optional(),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zip_code: Yup.string().length(5).required('Zip Code is required'),
});

const formatFormValue = (formValue?: FormValues | SFCLocation): CreateFormValues => {
  return JSON.parse(JSON.stringify(formValue)) as CreateFormValues;
}

interface AddEditLocationProps {
  editing?: boolean;
}

export const AddEditLocation: React.FC<AddEditLocationProps> = ({ editing }) => {
  const currentUser = getCurrentUser();
  const params = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const { addToast } = useToast();
  const navigate = useNavigate();
  
  const [createLocation] = useCreateLocationMutation();
  const [updateLocation] = useUpdateLocationMutation();
  const { data, error, isLoading }  = useGetLocationQuery(Number(params.id!), { skip: !editing || !params.id });
  const [initialValues, setInitialValues] = useState<FormValues>({
    title: "",
    business_entity_name: "",
    phone_number: "",
    primary_city: "",
    // manager: {},
    scheduling_email: "",
    invoicing_email: "",
    hotline: "",
    tax_id_number: "",
    is_active: true,
    addresses: [],
    latitude: 0,
    longitude: 0,
    zoom: 0,
    location_information_facebook: "",
    location_information_instagram: "",
    location_information_who_we_are: "",
    location_information_franchise_owner_first_name: "",
    location_information_franchise_owner_last_name: "",
    location_information_jobs_url: "",
    location_information_franchise_owner_email: "",

    images: [],
    franchise_images: [],
  });

  const validationSchemas = [
    Yup.object({
      title: Yup.string().required('Title is required'),
      business_entity_name: Yup.string().optional(),
      phone_number: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .min(10, 'Phone number should have at least 10 digits')
        .optional(),
      primary_city: Yup.string().required('City is required'),
      scheduling_email: Yup.string().email().required('Scheduling Email is required'),
      invoicing_email: Yup.string().email().optional(),
      hotline: Yup.string()
        .matches(phoneRegExp, 'Chef Hotline is not valid')
        .min(10, 'Chef Hotline should have at least 10 digits')
        .optional(),
      tax_id_number: Yup.string().optional(),
      is_active: Yup.boolean().required().default(true),
      addresses: Yup.array().of(RemittanceAddressSchema).optional(),
      latitude: Yup.number().required(),
      longitude: Yup.number().required(),
      zoom: Yup.number().required(),

      location_information_facebook: Yup.string().url().optional(),
      location_information_instagram: Yup.string().url().optional(),
      location_information_who_we_are: Yup.string().optional(),
      location_franchise_owner_first_name: Yup.string().optional(),
      location_franchise_owner_last_name: Yup.string().optional(),
      location_franchise_owner_email: Yup.string().optional(),
      location_jobs_url: Yup.string().url().optional(),

      document_type: Yup.string().url().optional()
    }),
    Yup.object({
      files: Yup.array().optional(),
      file_metadata: Yup.array(),
    }),
  ];

  useEffect(() => {
    if (editing && error && !isLoading) {
      addToast('Error while loading session', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editing, error, isLoading]);

  useEffect(() => {
    if (data && data.location) {
      const { location } = data;
      let values = {
        ...location, 
        ...{manager_id: {value: location?.manager?.id ?? 0, label: location?.manager?.full_name ?? ""},
        location_information_facebook: location?.location_information?.facebook,
        location_information_instagram: location?.location_information?.instagram,
        location_information_franchise_owner_first_name: location?.location_information?.franchise_owner_first_name,
        location_information_franchise_owner_last_name: location?.location_information?.franchise_owner_last_name,
        location_information_franchise_owner_email: location?.location_information?.franchise_owner_email,
        location_information_jobs_url: location?.location_information?.jobs_url,
        location_information_who_we_are: location?.location_information?.who_we_are},

        images: convertLocationImages(location?.location_images),
        franchise_images: convertLocationImages(location?.franchise_images),
        contact_image: location?.location_information ? convertContactImage(location?.location_information) : undefined
      };
      setInitialValues(values);

      if(!userCanEdit(currentUser, location.id)) {
        addToast('You have no permissions to edit this location', 'error');
        navigate(`/admin/places/locations`);
      }
    }
  }, [data]);


  const handleAddLocation = async (formValue: FormValues) => {
    try {
      let submission = formatFormValue(formValue);

      submission.manager_id = formValue.manager_id?.value ?? 0;

      submission.location_information = {
        facebook: formValue.location_information_facebook,
        instagram: formValue.location_information_instagram,
        franchise_owner_first_name: formValue.location_information_franchise_owner_first_name,
        franchise_owner_last_name: formValue.location_information_franchise_owner_last_name,
        franchise_owner_email: formValue.location_information_franchise_owner_email,
        jobs_url: formValue.location_information_jobs_url,
        who_we_are: formValue.location_information_who_we_are
      };

      try {
        if (editing && data?.location) {
          let formattedLocation = formatFormValue(formatResponse(data?.location));

          formattedLocation.location_information = {
            facebook: formValue.location_information_facebook,
            instagram: formValue.location_information_instagram,
            franchise_owner_first_name: formValue.location_information_franchise_owner_first_name,
            franchise_owner_last_name: formValue.location_information_franchise_owner_last_name,
            franchise_owner_email: formValue.location_information_franchise_owner_email,
            jobs_url: formValue.location_information_jobs_url,
            who_we_are: formValue.location_information_who_we_are
          };

          let update = getObjectDifference(formattedLocation, submission);

          update.phone_number = formValue.phone_number;
          update.id = data?.location.id;
          update.location_information = formattedLocation.location_information;
          update.title = data?.location.title;
          update.primary_city = data?.location.primary_city;
          update.manager_id = formValue.manager_id?.value ?? 0;
          update.is_active = data?.location.is_active;
          update.latitude = data?.location.latitude;
          update.longitude = data?.location.longitude;
          update.zoom = data?.location.zoom;
          update.contact_image = formValue.contact_image;
          update.images = formValue.images;
          update.franchise_images = formValue.franchise_images;
          const res = await updateLocation(update as CreateFormValues).unwrap();
          if (res && res.location) {
            addToast(`Location updated succesfully`, 'success');
          }

          navigate(`/admin/places/locations/${data?.location.id}`);
        } else {
          const res = await createLocation(submission).unwrap();
          if (res && res.location) {
            navigate(`/admin/places/locations/${res.location.id}`);
            addToast('Location created succesfully', 'success');
          } else throw new Error('A problem happened while creating Location');
        }
      } catch (e) {
        addToast(getErrorMessage(e), 'error');
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FormValues>>, 
    setTouched: (touched: FormikTouched<FormValues>, shouldValidate?: boolean) => void,
    values: FormValues
  ) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        title: true,
        business_entity_name: true,
        phone_number: true,
        primary_city: true,
        manager_id: true,
        scheduling_email: true,
        invoicing_email: true,
        hotline: true,
        tax_id_number: true,
        is_active: true,
        addresses: Array.isArray(errors.addresses) ? errors.addresses?.map(() => getNestedTouchedTrue(RemittanceAddressSchema)) : [],
        latitude: true,
        longitude: true,
        zoom: true,

        location_information_facebook: true,
        location_information_instagram: true,
        location_information_who_we_are: true,
        location_information_franchise_owner_first_name: true,
        location_information_franchise_owner_last_name: true,
        location_information_franchise_owner_email: true,
        location_information_jobs_url: true
      });
    }
  };

  return (
    <FadeIn className="p-lg-4">
      <Stack gap={3} className="py-2 py-lg-0 px-4">
        <div>
          <BackButton
            text={`Back to location${editing ? ' details' : 's'}`}
            color="#2B4E64"
            url={editing ? `/admin/places/locations/${data?.location.id}` : '/admin/places/locations'}
          />
          <h2
            className="d-none d-lg-block text-info fw-bold mb-0"
            style={{ fontSize: '2.25rem' }}
          >
            {editing ? 'Edit' : 'New'} Location
          </h2>
          {steps.length > 1 && <Stepper steps={steps} currentStep={currentStep} editing={editing === true} onStepClick={(step) => setCurrentStep(step)} />}
        </div>

        <div
          className="p-3 p-lg-5"
          style={{
            boxShadow: '0 4px 8px -2px rgba(16, 24, 40, .1),  0 4px 4px 0 rgba(0, 0, 0, .25)',
            border: '1px solid #EBEBEB',
            borderRadius: 10,
          }}
        >
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[currentStep]}
              onSubmit={handleAddLocation}
              enableReinitialize
            >
              {({ submitForm, isSubmitting, validateForm, touched, errors, setTouched, values, setFieldValue,  }: FormikProps<FormValues>) => (
                <FormikForm className="text-start">
                  <div style={{ minHeight: '65vh' }}>
                    {currentStep < steps.length
                      && React.createElement(steps[currentStep]?.form, {
                        errors,
                        touched,
                        values,
                        setFieldValue,
                        editing
                      })
                    }
                  </div>

                  <Row className="justify-content-end mt-3">
                    <Col xs={6} lg={3}>
                      {currentStep !== 0 && (
                        <SimpleButton
                          disabled={isSubmitting}
                          type="button"
                          onClick={() => setCurrentStep(currentStep - 1)}
                          className="w-100"
                          variant="outline-primary"
                        >
                          Back
                        </SimpleButton>
                      )}
                    </Col>

                    <Col xs={6} lg={3}>
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={currentStep === steps.length - 1 ? submitForm : () => handleNext(validateForm, setTouched, values) }
                        className="w-100"
                        variant="primary"
                      >
                        {currentStep === steps.length - 1 ? 'Finish' : 'Continue'}
                      </SimpleButton>
                    </Col>                   
                  </Row>
                </FormikForm>
              )}
            </Formik>
        </div>
      </Stack>
    </FadeIn>
  );
};
