import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

import { useActionsWaitlistMutation } from "../../../../../services/endpoints/schedule/waitlist";
import { getErrorMessage } from "../../../../../utils/utils";
import { useToast } from "../../../../../context";
import { useParams } from "react-router-dom";




export const SessionWaitlist = () => {
  const params = useParams();
  const { addToast } = useToast();
  const {action,id}=params;
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [ waitlist ] = useActionsWaitlistMutation();
  



  useEffect(() => {
  const processWaitlist = async () => {
    try {
      if (action && id !== null && id !== undefined && Number(id) > 0) {
        const orderPayload = {
          id: Number(id),
          action: action,
        };

        await waitlist(orderPayload).unwrap();
        addToast('Added to waitlist!', 'success');
      }
    } catch (error) {
      addToast(getErrorMessage(error), 'error');
    }
  };

  processWaitlist();
}, [action, id, waitlist, addToast]);  // Added dependencies

  

  if (isLoading) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    );
  }

  

  return (
    <>
    
    </>
  );
};
