import { useEffect, useState } from 'react';
import SFCTable from '../../../../components/tables/SFCTable';
import { useToast } from '../../../../context/ToastContext';
import { GetQueryParams } from '../../../../types/api.type';
import { FilterField, FilterForm } from '../../../../components/tables/filterForms/FilterForm';
import { ColumnDef } from '@tanstack/react-table';
import { LinkCell } from '../../../../components/tables/cellComponents/LinkCell';
import { Stack } from 'react-bootstrap';
import { Order, PromotionalCode, Refund } from '../../../../types/orders.type';
import { FormSelect } from '../../../../components/tables/filterForms/FormSelect';
import { Scopes, User } from '../../../../services/endpoints/people/user';
import { ButtonData } from '../../../../components/buttonGroup/ButtonGroup';
import { useGetCoursesSimpleQuery } from '../../../../services/endpoints/schedule/course';
import { Course } from '../../../../types/course.type';
import { useGetAllParentsQuery } from '../../../../services/endpoints/people/parent';
import { formatDate } from '../../../../utils/dateUtils';
import { useGetAllOrdersQuery } from '../../../../services/endpoints/orders/orders';
import { useGetAllLocationsSimpleQuery } from '../../../../services/endpoints/places/location';
import { useGetAllPromotionalCodesQuery } from '../../../../services/endpoints/orders/promotionalCode';
import CurrencyCell from '../../../../components/tables/cellComponents/CurrencyCell';
import { SearchField } from '../../../../components/tables/filterForms/SearchField';
import { DetailSection, LabelText } from '../../../../components/generic';
import { getCurrentUser } from '../../../../services/helper';
import { DateField } from '../../../../components/tables/filterForms/DateField';

const defaultScope = 'completed';
const currentUser = getCurrentUser();

export const userCanCreate_Orders = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin || user?.isAdmin || user?.isGlobal || user?.isLocationAdmin) {
    return true;
  }

  return false;
}

export const userCanViewRemoveSubmit_Orders = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin || user?.isAdmin || user?.isGlobal || user?.isLocationAdmin || user?.isInstructor) {
    return true;
  }

  return false;
}

export const ordersColumns: ColumnDef<Order>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
  },
  {
    header: 'Parent',
    accessorKey: 'parent_id',
    cell: ({ row }) =>
      <LinkCell
        content={`${row.original.parent.first_name} ${row.original.parent.last_name}`}
        url={`/admin/dash-users/users/${row.original.parent_id}`}
      />,
    enableSorting: false,
  },
  {
    header: 'Children',
    accessorKey: 'child_full_name',
    cell: ({ row }) => !row.original.registrations?.length ? '' :
      <Stack>
        {row.original.registrations.map((item) => (
          <LinkCell
            content={`${item.child?.first_name} ${item.child?.last_name}`}
            // TODO: add correct child detail page URL
            url={`/admin/sfc-users/children/${item.child_id}`}
          />
        ))}
      </Stack>,
    enableSorting: false,
  },
  {
    header: 'Session',
    accessorKey: 'course_id',
    cell: ({ row }) => !row.original.course ? '' :
      <LinkCell
        content={row.original.course.title}
        url={`/admin/schedule/sessions/${row.original.course_id}`}
      />,
    enableSorting: false,
  },
  {
    header: 'Email',
    accessorKey: 'parent_email',
    cell: ({ row }) => row.original.parent.email,
    enableSorting: false,
  },
  {
    header: 'Amount Paid',
    accessorKey: 'total',
    cell: ({ getValue }) => <CurrencyCell value={getValue<number>()} />,
    enableSorting: false,
  },
  {
    header: 'Refunds',
    accessorKey: 'refunds',
    enableSorting: false,
    cell: ({ getValue }) => (
      <>
        {getValue<Refund[]>().map((r, index) => (
          <CurrencyCell key={index} value={r.total} label={`on ${formatDate(new Date(r.created_at), 'MM/dd/yyyy')}`} />
        ))}
      </>
    )
  },
  {
    header: 'Created At',
    accessorKey: 'created_at',
    cell: ({ row }) => formatDate(new Date(row.original.created_at), 'MM/dd/yyyy h:mm zzz')
  },
  {
    header: () => null,
    id: 'actions',
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell content="View" url={`/admin/orders/orders/${row.original.id}`} underline />
      </Stack>
    )
  },
];

export const OrdersView = () => {
  const { addToast } = useToast();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope, data: {entries: 10, page: 1} });
  const [scopes, setScopes] = useState<Scopes>({});
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const { data, error, isLoading, isFetching } = useGetAllOrdersQuery(filters);
  const [orders, setOrders] = useState<Order[]>([]);;
  const { data: coursesData, error: coursesError } = useGetCoursesSimpleQuery();
  const { data: parentData, error: parentError } = useGetAllParentsQuery();
  const { data: locationData, error: locationError } = useGetAllLocationsSimpleQuery();
  const { data: promoCodesData, error: promoCodesError } = useGetAllPromotionalCodesQuery();

  const filterFormFields: FilterField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'number',
    },
    {
      name: 'parent_id',
      type: 'number',
      element: (
        <FormSelect
          name="parent_id"
          label="Parent"
          options={parentData?.results?.map((parent: User) => ({ value: parent.id, label: `${parent.first_name} ${parent.last_name}` })) ?? []}
          loadingError={parentError ? 'Error loading parents' : undefined}
        />
      ),
    },
    {
      name: 'course_id',
      type: 'number',
      element: (
        <FormSelect
          name="course_id"
          label="Session"
          options={coursesData?.results?.map((course: Course) => ({ value: course.id, label: course.title })) ?? []}
          loadingError={coursesError ? 'Error loading sessions' : undefined}
        />
      ),
    },
    {
      name: 'parent_email',
      element: (
        <SearchField name="parent_email" label="Email" placeholder="Search parent email" />
      )
    },
    {
      name: 'promotional_code_id',
      type: 'number',
      element: (
        <FormSelect
          name="promotional_code_id"
          label="Promotional Code"
          options={promoCodesData?.results?.map((promo: PromotionalCode) => ({ value: promo.id, label: promo.name })) ?? []}
          loadingError={promoCodesError ? 'Error loading promotional codes' : undefined}
        />
      ),
    },
    {
      name: 'created_at_from',
      id: 'created_at',
      operator: 'greater_equal_than',
      element: (
        <DateField name="created_at_from" label="Created At" />
      )
    },
    {
      name: 'created_at_to',
      id: 'created_at',
      operator: 'lesser_equal_than',
      element: (
        <DateField name="created_at_to" />
      )
    }
  ];
  
  if(currentUser?.isGlobal) {
    filterFormFields.push({
      name: 'location_id',
      label: 'Location',
      element: (
        <FormSelect
          name="location_id"
          label="Location"
          options={locationData?.map((loc) => ({ value: loc.id, label: loc.title })) ?? []}
          loadingError={locationError ? 'Error loading locations' : undefined}
        />
      ),
    });
  }

  useEffect(() => {
    if (!isLoading && error) {
      addToast('Error while loading orders', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isLoading]);

  useEffect(() => {
    if (!error && !isLoading && data && data.results) {
      if (data.scopes) {
        setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({...acc, ...curr}), {} as Scopes));
        setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => ({
          title: title,
          key: scope,
        }))));
      }
    } else if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setOrders(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    console.log(filterBy);
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`${scopes[filters?.scope!] ?? ''} Orders`}
        columns={ordersColumns}
        data={orders}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        filters={filters.data?.filterBy}
        indexDownloadPath='orders'
        sidebars={currentUser?.canSeeFinancials ? [
          <DetailSection title="Summary" >
            <LabelText label="Amount Paid:">
              <CurrencyCell value={data?.totals?.total ?? 0} />
            </LabelText>
          </DetailSection>
        ] : []}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
      />
    </div>
  );
}
