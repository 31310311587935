import { ButtonData } from '../../../../../components/buttonGroup/ButtonGroup';
import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { Scopes, User } from '../../../../../services/endpoints/people/user';
import { themesColumns } from '../../../../../utils/consts/columnDefs/contentColumns';
import { useGetThemesQuery } from '../../../../../services/endpoints/content/theme';
import { Theme } from '../../../../../types/theme.type';
import { getCurrentUser } from '../../../../../services/helper';
import { DateField } from '../../../../../components/tables/filterForms/DateField';
import { FormSelect, FormSelectOptions } from '../../../../../components/tables/filterForms/FormSelect';

const defaultScope = 'all';

export const userCanEditCreateDeleteView_Themes = (user: User | null) => {
  if(user?.isAdmin && user?.isSuper) {
    return true;
  }
  if(user?.isWebContentEditor || user?.isSupremeAdmin) {
    return true;
  }

  return false;
}

export const ThemesView = () => {
  const { addToast } = useToast();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope, data: {page: 1, entries: 10} });
  const [scopes, setScopes] = useState<Scopes>({});
  const { data, error, isLoading, isFetching } = useGetThemesQuery(filters);
  const [Themes, setThemes] = useState<Theme[]>([]);
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const navigate: NavigateFunction = useNavigate();
  const currentUser = getCurrentUser();

  const isPublicOptions: FormSelectOptions[] = [
    {
      value: 'true',
      label: 'True'
    },
    {
      value: 'false',
      label: 'False'
    }
  ];

  const filterFormFields: FilterField[] = [
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'created_at_from',
      id: 'created_at',
      operator: 'greater_equal_than',
      element: (
        <DateField name="created_at_from" label="Created At" />
      )
    },
    {
      name: 'created_at_to',
      id: 'created_at',
      operator: 'lesser_equal_than',
      element: (
        <DateField name="created_at_to" />
      )
    },
    {
      name: 'is_public',
      element: (
        <FormSelect
          name="is_public"
          label="Is Public"
          options={isPublicOptions}
        />
      )
    }
  ];

  useEffect(() => {
    if (!error && !isLoading && data && data.results) {
      if (data.scopes) {
        setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({...acc, ...curr}), {} as Scopes));
        setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => ({
          title: title,
          key: scope,
        }))));
      }
    } else if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
     
        setThemes(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`${scopes[filters?.scope!] ?? ''} Themes`}
        columns={themesColumns}
        data={Themes}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        indexDownloadPath='themes'
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
        newItemEl={userCanEditCreateDeleteView_Themes(currentUser) && (
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={250}
            onClick={() => navigate('/admin/content/themes/new')}
          >
            New Theme
          </SimpleButton>
        )}
      />
    </div>
  );
}
