import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { ColumnDef } from '@tanstack/react-table';
import { LinkCell } from '../../../../../components/tables/cellComponents/LinkCell';
import { Stack } from 'react-bootstrap';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { useNavigate } from 'react-router-dom';
import { scholarshipTypes } from '../../../../../utils/consts/orders/scholarships';
import { Discount, Scholarship } from '../../../../../types/orders.type';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { Child, Scopes, useGetAdminsQuery, User } from '../../../../../services/endpoints/people/user';
import { ButtonData } from '../../../../../components/buttonGroup/ButtonGroup';
import { useGetAllDiscountsQuery } from '../../../../../services/endpoints/orders/discounts';
import { useGetCoursesQuery } from '../../../../../services/endpoints/schedule/course';
import { Course } from '../../../../../types/course.type';
import { useGetAllChildrenQuery } from '../../../../../services/endpoints/people/child';
import { useGetAllScholarshipsQuery } from '../../../../../services/endpoints/orders/scholarship';
import { useGetAllParentsQuery } from '../../../../../services/endpoints/people/parent';
import { getCurrentUser } from '../../../../../services/helper';
import { DateField } from '../../../../../components/tables/filterForms/DateField';

export const discountColumns: ColumnDef<Discount>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
  },
  {
    header: 'Admin',
    accessorKey: 'admin_id',
    cell: ({ row }) =>
      <LinkCell
        // TODO: change with admin data
        content={`${row.original.user.first_name} ${row.original.user.last_name}`}
        url={`/admin/dash-users/admins/${row.original.admin_id}`}
      />,
  },
  {
    header: 'Parent',
    accessorKey: 'user_id',
    cell: ({ row }) =>
      <LinkCell
        content={`${row.original.user.first_name} ${row.original.user.last_name}`}
        url={`/admin/dash-users/users/${row.original.user_id}`}
      />,
  },
  {
    header: 'Scholarship',
    accessorKey: 'scholarship_id',
    cell: ({ row }) => <>{scholarshipTypes[row.original.scholarship.scholarship_type]} ({row.original.scholarship.percent_discount}%&nbsp;off)</>,
  },
  {
    header: 'Session',
    accessorKey: 'course_id',
    cell: ({ row }) => !row.original.course ? '' :
      <LinkCell
        content={row.original.course.title}
        url={`/admin/schedule/sessions/${row.original.course_id}`}
      />,
  },
  {
    header: 'Child',
    accessorKey: 'child_id',
    cell: ({ row }) => !row.original.child ? '' :
      <LinkCell
        content={`${row.original.child.first_name} ${row.original.child.last_name}`}
        // TODO: add correct child detail page URL
        url={`/admin/users/children/${row.original.child_id}`}
      />,
  },
  {
    header: 'Order',
    accessorKey: 'order_id',
    cell: ({ row }) => !row.original.order_id ? '' :
      <LinkCell
        content={`Order #${row.original.order_id}`}
        url={`/admin/orders/orders/${row.original.order_id}`}
      />,
  },
  {
    header: 'Gift Certificate',
    accessorKey: 'gift_certificate_id',
  },
  {
    header: () => null,
    id: 'actions',
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        {userCanAdmin_Discounts(currentUser) && (
          <>
            <LinkCell content="View" url={`/admin/orders/discounts/${row.original.id}`} underline />
            <LinkCell content="Edit" url={`/admin/orders/discounts/${row.original.id}/edit`} underline />
          </>
        )}
      </Stack>
    )
  },
];

const defaultScope = 'all';
const currentUser = getCurrentUser();

export const userCanAdmin_Discounts = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin || user?.isAdmin || user?.isGlobal || user?.isLocationAdmin) {
    return true;
  }

  return false;
}

export const DiscountsView = () => {
  const { addToast } = useToast();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: '' });
  const [scopes, setScopes] = useState<Scopes>({});
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const { data, error, isLoading, isFetching } = useGetAllDiscountsQuery(filters);
  const [discounts, setDiscounts] = useState<Discount[]>([]);;
  const navigate = useNavigate();
  const { data: coursesData, error: coursesError } = useGetCoursesQuery();
  const { data: parentData, error: parentError } = useGetAllParentsQuery();
  const { data: childrenData, error: childrenError } = useGetAllChildrenQuery();
  const { data: adminData, error: adminError } = useGetAdminsQuery();
  const { data: scholarshipData, error: scholarshipError } = useGetAllScholarshipsQuery();

  const filterFormFields: FilterField[] = [
    {
      name: 'course_id',
      type: 'number',
      element: (
        <FormSelect
          name="course_id"
          label="Session"
          options={coursesData?.results?.map((course: Course) => ({ value: course.id, label: course.title })) ?? []}
          loadingError={coursesError ? 'Error loading sessions' : undefined}
        />
      ),
    },
    {
      name: 'user_id',
      type: 'number',
      element: (
        <FormSelect
          name="user_id"
          label="Parent"
          options={parentData?.results?.map((parent: User) => ({ value: parent.id, label: `${parent.first_name} ${parent.last_name}` })) ?? []}
          loadingError={parentError ? 'Error loading parents' : undefined}
        />
      ),
    },
    {
      name: 'child_id',
      type: 'number',
      element: (
        <FormSelect
          name="child_id"
          label="Child"
          options={childrenData?.results?.map((child: Child) => ({ value: child.id!, label: `${child.first_name} ${child.last_name}` })) ?? []}
          loadingError={childrenError ? 'Error loading children' : undefined}
        />
      ),
    },
    {
      name: 'admin_id',
      type: 'number',
      element: (
        <FormSelect
          name="admin_id"
          label="Admin"
          options={adminData?.results?.map((admin: User) => ({ value: admin.id, label: `${admin.first_name} ${admin.last_name}` })) ?? []}
          loadingError={adminError ? 'Error loading admins' : undefined}
        />
      ),
    },
    {
      name: 'order_id',
      type: 'number',
      label: 'Order Number'
    },
    {
      name: 'gift_certificate_id',
      type: 'number',
      label: 'Gift Certificate'
    },
    {
      name: 'scholarship_id',
      type: 'number',
      element: (
        <FormSelect
          name="scholarship_id"
          label="Scholarship"
          options={scholarshipData?.results?.map((item: Scholarship) => ({
            value: item.id,
            label: `${scholarshipTypes[item.scholarship_type]} (${item.percent_discount}% off)`
          })) ?? []}
          loadingError={adminError ? 'Error loading scholarships' : undefined}
        />
      ),
    },
    {
      name: 'created_at_from',
      id: 'created_at',
      operator: 'greater_equal_than',
      element: (
        <DateField name="created_at_from" label="Created At" />
      )
    },
    {
      name: 'created_at_to',
      id: 'created_at',
      operator: 'lesser_equal_than',
      element: (
        <DateField name="created_at_to" />
      )
    }
  ];
  
  useEffect(() => {
    if (!isLoading && error) {
      addToast('Error while loading discounts', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isLoading]);

  useEffect(() => {
    if (!error && !isLoading && data && data.results) {
      if (data.scopes) {
        setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({...acc, ...curr}), {} as Scopes));
        setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => ({
          title: title,
          key: scope,
        }))));
      }
    } else if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setDiscounts(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`${scopes[filters?.scope!] ?? ''} Discounts`}
        columns={discountColumns}
        data={discounts}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        filters={filters.data?.filterBy}
        indexDownloadPath='discounts'
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
        newItemEl={userCanAdmin_Discounts(currentUser) && (
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={280}
            onClick={() => navigate('/admin/orders/discounts/new')}
          >
            New discount
          </SimpleButton>
        )}
      />
    </div>
  );
}
